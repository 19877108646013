import React from 'react'
import styled from 'styled-components'
import theme from '../theme'

const ThreeColumns = ({ children }) => {
  return <ThreeColumnsContainer>{children}</ThreeColumnsContainer>
}

export default ThreeColumns

const ThreeColumnsContainer = styled.div`
  .columns {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    gap: 2rem;

    h4 {
      margin: 0 auto;
      margin-bottom: 1rem;
      max-width: 400px;
    }

    .column {
      display: flex;
      flex-direction: column;
    }

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      flex-direction: row;

      &.three-columns {
        .column {
          width: 33.33%;
        }
      }
    }
  }

  .columns-reverse {
    .column {
      display: flex;
      flex-direction: column;
    }

    h4 {
      margin-bottom: 1rem;
      margin-top: 0;
    }

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      h4 {
        margin-bottom: 0;
        margin-top: 1rem;
      }

      &.three-columns {
        .column {
          width: 33.33%;
          flex-direction: column-reverse;
        }
      }
    }
  }
`
