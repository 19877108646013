/* eslint-disable no-irregular-whitespace */
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Block from '../components/Block'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import CTASection from '../components/CTASection'
import { Feature } from '../components/Feature'
import { Hero } from '../components/Hero'
import Layout from '../components/Layout'
import { LeadHeading } from '../components/LeadHeading'
import MainDemoRequestCTA from '../components/MainDemoRequestCTA'
import Page from '../components/Page'
import ThreeColumns from '../components/ThreeColumns'
import theme from '../theme'
//9 feature icons
import InvestmentInsights from '../assets/images/icons/barchart.svg'
import BugInsights from '../assets/images/icons/castle.svg'
import WIPLimits from '../assets/images/icons/checklist.svg'
import ReviewTime from '../assets/images/icons/clock.svg'
import CycleTime from '../assets/images/icons/cycle.svg'
import ReviewRate from '../assets/images/icons/percentage.svg'
import CIInsights from '../assets/images/icons/robot-hand.svg'
import DeploymentFrequency from '../assets/images/icons/rocket.svg'
import BatchSize from '../assets/images/icons/size.svg'
//Explore next
import MetaImage from '../assets/images/engineering-metrics/engineering-metrics-meta.png'
import continuousIcon from '../assets/images/icons/ways.svg'
import { ReadNext } from '../components/ReadNext'
import QuoteBlock from '../components/blocks/QuoteBlock'

const SwarmiaForDevsPage = () => {
  const developerImages = useStaticQuery(graphql`
    query {
      feature1: file(
        relativePath: {
          eq: "images/engineering-metrics/feature-investment-insights.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature2: file(
        relativePath: {
          eq: "images/engineering-metrics/feature-flow-insights.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature3: file(
        relativePath: { eq: "images/engineering-metrics/feature-health.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      glance1: file(
        relativePath: { eq: "images/for-developers/plug-in-tools.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
          )
        }
      }
      glance2: file(
        relativePath: { eq: "images/for-developers/get-team-insights.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
            height: 300
          )
        }
      }
      glance3: file(
        relativePath: {
          eq: "images/engineering-metrics/glance-no-harmful-metrics.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
          )
        }
      }
      feature4: file(
        relativePath: { eq: "images/for-developers/digest-big.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature5: file(
        relativePath: { eq: "images/for-developers/feature-review-wa-big.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="Software engineering KPIs for high-performing teams"
      description="Swarmia gives software engineering leaders, managers, and teams access to a variety of KPIs. Here’s what you can (and can’t) measure with Swarmia."
      metaImage={MetaImage}
      variant="dark"
    >
      <Hero
        bigPadding
        background={
          <StaticImage
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: -1,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            src="../assets/images/backgrounds/swarmia-metrics.png"
            aria-hidden="true"
            alt=""
            quality={90}
          />
        }
      >
        <Block type="padding-medium">
          <h1>Engineering KPIs for high-performing teams</h1>
          <div className="hero-description">
            <p className="large_text">
              Swarmia gives software engineering leaders, managers, developers,
              and teams access to a variety of useful KPIs. Here’s what you can
              (and can’t) measure with&nbsp;Swarmia.
            </p>
          </div>
          <MainDemoRequestCTA />
        </Block>
      </Hero>
      <Page type="padding-bottom-small padding-top-none layout-index">
        <Block type="padding-top-small padding-bottom-medium">
          <LeadHeading title="Drive positive change with healthy key performance indicators" />
          <ThreeColumns>
            <div className="columns columns-reverse three-columns">
              <div className="column">
                <h4>Connect the dots between GitHub, Jira/Linear, and Slack</h4>
                <div>
                  <GatsbyImage
                    image={
                      developerImages.glance1.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="column">
                <h4>
                  Get relevant KPIs for every level of the engineering
                  department
                </h4>
                <div className="image-wrapper">
                  <GatsbyImage
                    image={
                      developerImages.glance2.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="column">
                <h4>
                  No harmful leaderboards or data on individual performance
                </h4>
                <div className="image-wrapper">
                  <GatsbyImage
                    image={
                      developerImages.glance3.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </ThreeColumns>
        </Block>
        <Block size="large" type="padding-bottom-large padding-top-small">
          <LeadHeading
            title="What type of engineering KPIs can I measure with Swarmia?"
            description="Swarmia is not just a dashboard for the engineering leadership. In addition to high-level KPIs, it also gives teams visibility into their workflow and possible bottlenecks."
          />
          <div className="features full-width-images" id="features">
            <Feature
              type="feature feature-grid-right"
              heading="Business impact"
              image={developerImages.feature1.childImageSharp.gatsbyImageData}
              imageAlt="Investment insights in Swarmia"
            >
              <p>
                Quickly understand how much time your engineering organization
                is spending on different types of work.
              </p>
              <p>
                Use these insights to guide your engineering teams’ focus
                between roadmap work, feature improvements, maintenance, and/or
                any other categories you may want to track.
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-left"
              heading="Flow"
              image={developerImages.feature2.childImageSharp.gatsbyImageData}
              imageAlt="Flow insights in Swarmia"
            >
              <p>
                Flow metrics like pull request cycle time, cycle time for
                issues, and deployment frequency allow engineering teams to see
                how well the engine is running.
              </p>
              <p>
                By analyzing outliers and periods of inactivity, you’ll be able
                to recognize and resolve systemic issues that negatively impact
                throughput. Additionally, Swarmia’s Slack notifications nudge
                your team to keep code flowing and cycle times low.
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-right"
              heading="Team and code health"
              image={developerImages.feature3.childImageSharp.gatsbyImageData}
              imageAlt="Swarmia's working agreements"
            >
              <p>
                There are two ways you can look at the health of an engineering
                organization: the health of the team and the quality of the code
                they write.
              </p>
              <p>
                Swarmia helps you build good habits like limiting work in
                progress, working together on bigger issues, and evenly
                distributing code reviews within the team. You can also easily
                keep track of bugs and their share of all work.
              </p>
            </Feature>
          </div>
        </Block>
        <QuoteBlock person="roland" />

        <FeaturesGridWrapper>
          <Block size="large" type="padding-large">
            <div className="lead-heading">
              <h2>It’s hard to improve what you don’t measure</h2>
              <p>
                Here are some of the healthy software engineering KPIs you’ll
                see in Swarmia.
              </p>
            </div>
            <FeaturesGrid>
              <div>
                <img src={CycleTime} alt="Cycle time" />
                <h4>Cycle time</h4>
                <p>
                  Understand how long it takes for your team to complete pull
                  requests, tasks, stories, and epics.
                </p>
              </div>
              <div>
                <img src={DeploymentFrequency} alt="Deployment frequency" />
                <h4>Deployment frequency</h4>
                <p>
                  Keep track of how often you’re deploying — and how often the
                  deployments are failing.
                </p>
              </div>
              <div>
                <img src={InvestmentInsights} alt="Investment distribution" />
                <h4>Investment distribution</h4>
                <p>
                  Know what your engineering teams are working on and establish
                  the right balance between different types of work.
                </p>
              </div>
              <div>
                <img src={BugInsights} alt="Bug Insights" />
                <h4>Bug Insights</h4>
                <p>
                  Stay on top of the bugs your team is working on and the time
                  it takes to fix them.
                </p>
              </div>
              <div>
                <img src={BatchSize} alt="Batch size" />
                <h4>Batch size</h4>
                <p>
                  Keep your batch size small to roll out features to customers
                  faster and with less risk.
                </p>
              </div>
              <div>
                <img src={CIInsights} alt="CI insights" />
                <h4>CI insights</h4>
                <p>
                  Spot flaky tests and improve the performance of your CI
                  pipeline.
                </p>
              </div>
              <div>
                <img src={ReviewTime} alt="Review time" />
                <h4>Review time</h4>
                <p>
                  Identify review bottlenecks and get your work merged faster.
                </p>
              </div>
              <div>
                <img src={ReviewRate} alt="Review rate" />
                <h4>Review rate</h4>
                <p>
                  Maintain high code quality by maximizing the percentage of
                  code that gets reviewed before it’s merged.
                </p>
              </div>
              <div>
                <img src={WIPLimits} alt="WIP limits" />
                <h4>WIP limits</h4>
                <p>
                  Allow teams to set their own work in progress limits to boost
                  focus and productivity.
                </p>
              </div>
            </FeaturesGrid>
          </Block>
        </FeaturesGridWrapper>

        <Block type="padding-top-small padding-bottom-large">
          <LeadHeading
            title="There are some things you can’t measure with Swarmia"
            description="Just because you can measure a certain KPI doesn’t mean you should. We’ve consciously left out some key performance indicators that typically do more harm than good."
          />
          <ThreeColumns>
            <div className="columns three-columns">
              <div className="column">
                <h4>Developer stack ranking</h4>
                <p>
                  Swarmia focuses on improving development productivity on the
                  team and organization level. That’s why you won’t find
                  developer leaderboards or other harmful activity metrics in
                  Swarmia.
                </p>
              </div>
              <div className="column">
                <h4>Lines of code (LOC)</h4>
                <p>
                  Lines of code is a terrible measure of engineering
                  productivity. In fact, Swarmia only surfaces lines of code in
                  the context of batch size and code review notifications.
                </p>
              </div>
              <div className="column">
                <h4>Code churn and rework</h4>
                <p>
                  We believe that rewriting your own code is a perfectly
                  acceptable way to solve complex problems. That’s why you won’t
                  be able to track code churn or rework with Swarmia.
                </p>
              </div>
            </div>
          </ThreeColumns>
        </Block>
        <ReadNext
          label="Explore next"
          heading="Working Agreements"
          icon={continuousIcon}
          url="/product/working-agreements/"
        >
          <p className="large_text">
            Exposing the right KPIs is just the first step. To make sure your
            insights don’t just sit on a dashboard, Swarmia allows your teams to
            set their own Working Agreements.
          </p>
        </ReadNext>
      </Page>
      <CTASection>
        Swarmia is how the best teams get better. Get started with a demo or
        a 14-day trial.
      </CTASection>
      <FeaturedBlogArticles
        articles={[
          '/blog/git-analytics/',
          '/blog/measuring-software-development-productivity/',
          '/blog/dora-metrics/',
        ]}
        heading="Learn more from our blog"
      />
    </Layout>
  )
}

export default SwarmiaForDevsPage

const FeaturesGridWrapper = styled.div`
  border-top: 1px rgba(0, 0, 0, 0.05) solid;
  text-align: center;
  p {
    margin-top: 0.75rem;
  }
`

const FeaturesGrid = styled.div`
  margin: 0 auto;
  display: grid;
  grid-gap: 2rem 3rem;
  h3 {
    margin-top: 1.2rem;
  }
  h4 {
    margin-top: 1rem;
  }
  img {
    width: 64px;
    height: 64px;
  }
  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 4rem;
  }
  @media screen and (min-width: ${theme.breakpoints['large']}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
